import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  root: {
    overflow: "hidden",
    paddingBottom: "20px",
  },
  noClient: {
    fontSize: "18px",
    lineHeight: "21px",
    color: "#000000",
    fontFamily: "'Avenir Book', sans-serif",
    paddingTop: "73px",
  },

  icon: {
    width: "44.75px",
    height: "50px",
    marginRight: "10px",
    position: "relative",
    top: "15px",
  },

  cardContent: {
    fontFamily: "Avenir",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 350,
    marginBottom: "10px",
    textAlign: "center",
  },

  title: {
    fontFamily: "AvenirNext-DemiBold",
    fontSize: "15px",
  },

  subheader: {
    fontFamily: "AvenirNext-Regular",
    fontSize: "12px",
  },
  selector: {
    display: "flex",
    alignItems: "flex-end",
    marginTop: 15,
  },
  BePlanned: {
    "& > :first-child": {
      display: "flex",
      alignItems: "flex-end",
      marginRight: "15px",
      marginBottom: "-5px",
    },
  },
  beplannedSearchbar: {
    marginTop: "30px",
    "@media screen and (max-width: 750px)": {
      marginTop: "0px",
    },
  },
  archiveLabel: {
    fontFamily: "'Avenir Book', sans-serif !important",
    lineHeight: "22px",
    textTransform: "capitalize",
    textDecoration: "underline",
    color: "#abadad",
  },
  smHide: {
    "@media screen and (max-width: 1050px)": {
      display: "none",
    },
  },
  mdHide: {
    "@media screen and (min-width: 1050px)": {
      display: "none",
    },
  },
  subTitle: {
    fontFamily: "Avenir",
    fontSize: "24px",
    lineHeight: "48px",
    textAlign: "left",
    fontWeight: 400,
    padding: "10px",
  },
  fontWeight: {
    fontWeight: 900,
  },
  metricCardWrapper: {
    borderRadius: "10px",
    padding: "2px", // Space for the gradient
    background:
      "linear-gradient(97.21deg, #E0516B 0%, #5187E0 51.5%, #FFAB2D 100%)", // Gradient background
  },
  metricCard: {
    // height: "92px",
    borderRadius: "inherit", // Ensures the inner card has the same rounded corners
    backgroundColor: "white", // White background for inner content
    padding: "16px",
  },
  metricCardtext: {
    fontSize: "16px",
    fontWeight: 350,
  },
  metricCardNumbers: {
    fontSize: "20px",
    fontWeight: 900,
  },

  // skeleton loader styles
  loaderWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "16px", // Space between the skeleton blocks
  },
  skeletonLoader: {
    borderRadius: "4px",
    background: "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
    backgroundSize: "200% 100%",
    animation: "$shine 1.5s infinite ease-in-out",
  },
  skeletonText: {
    width: "70%", // Adjust width as needed
    height: "20px", // Adjust height as needed
  },
  skeletonNumber: {
    width: "30%", // Adjust width as needed
    height: "20px", // Adjust height as needed
  },
  "@keyframes shine": {
    "0%": {
      backgroundPosition: "200% 0",
    },
    "100%": {
      backgroundPosition: "-200% 0",
    },
  },

  metricCardSpacing: {
    padding: "10px",
    "@media screen and (max-width: 750px)": {
      padding: "0px",
    },
  },

  paginationPageInfo: {
    "@media screen and (min-width: 750px)": {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "20px",
    },
  },

  pageTitle: {
    fontFamily: "Avenir",
    fontSize: "30px",
    fontWeight: 900,
    lineHeight: "25px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  reportTitle: {
    marginTop: "10px",
    "@media screen and (min-width: 1050px)": {
      marginTop: "40px",
    },
  },
  activeSelectStyle: {
    color: "#000000",
    fontWeight: 900,
    fontSize: "21px",
    fontFamily: "Avenir",
    // underline the selected value
    underline: "2px solid #000000",
    cursor: "pointer",
    textDecoration: "underline",
    marginRight: "20px",
    marginBottom: "20px",
  },
  inactiveSelectStyle: {
    color: "#606060",
    fontWeight: 900,
    fontSize: "21px",
    fontFamily: "Avenir",
    cursor: "pointer",
    marginRight: "20px",
    marginBottom: "20px",
  },

  cardStyle: {
    fontFamily: "Avenir",
    backgroundColor: "#ffffff",
    borderRadius: "16px",
    marginBottom: "20px",
    padding: "10px",
  },
  pageTitleH2: {
    fontFamily: "Avenir",
    fontSize: "24px",
    fontWeight: 900,
    lineHeight: "25px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  btnStyle: {
    // width: "176px",
    height: "45px",
    padding: "8px 16px 8px 16px",
    gap: "0px",
    borderRadius: "15px",
    opacity: "0px",
    color: "white",
    backgroundColor: "#000000",
    // hover style
    "&:hover": {
      backgroundColor: "#000000",
    },
    "&:disabled": {
      backgroundColor: "gray",
      color: "darkgray",
    },
  },
  insightsContainer: {
    paddingBottom: "10px",
  },
  font: {
    fontFamily: "Avenir",
  },
  reportFilters: {
    paddingTop: "10px",
    paddingBottom: "10px",
    "@media screen and (min-width: 1050px)": {
      paddingTop: "10px",
      paddingBottom: "10px",
      paddingLeft: "10px",
    },
  },
  rightAlign: {
    textAlign: "right",
  },

  insightsContainerData: {
    padding: "10px",
    borderRadius: "16px",
    minHeight: "192px",
    border:
      "1px solid linear-gradient(97.21deg, #E0516B 0%, #5187E0 51.5%, #FFAB2D 100%)",
    backgroundColor: "#F2F2F2",
  },
});
