import { IUser } from "interfaces/IUser";

export const extractPlatforms = (user?: IUser) => {
  let platforms: string[] = [];
  if (user) {
    if (user?.facebook?.username) {
      platforms.push("Facebook");
    }

    if (user?.instagram?.username) {
      platforms.push("Instagram");
    }

    if (user?.youtube?.username) {
      platforms.push("Youtube");
    }

    if (user?.tiktok?.username) {
      platforms.push("TikTok");
    }

    if (user?.spotify?.username) {
      platforms.push("Spotify");
    }
  }

  return platforms;
};
