import axios from "axios";
import { contentMap } from "./contentmap";

export const generaterReport = async (
  userMessage: string,
  reportLength: "short" | "medium" | "long",
  type = "engagement"
): Promise<string | null> => {
  let maxTokens: number;

  // Adjust max_tokens based on the desired length
  switch (reportLength) {
    case "short":
      maxTokens = 500; // Short summary
      break;
    case "medium":
      maxTokens = 800; // Detailed report
      break;
    case "long":
      maxTokens = 1200; // Full analysis
      break;
    default:
      maxTokens = 800; // Default to medium
  }

  let content = contentMap[type];

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_FUNCTIONS_BASE_URL}chatgpt-chat`,
      {
        messages: [
          {
            role: "system",
            content:
              "You are an insightful data analyst with expertise in generating concise and structured reports based on raw data. Please do not include messages like 'Here is your HTML report' or statements like 'I cannot generate report in HTML.'. also do not add headers that was not specified in the template. Also date should be shown in long format. When listing items use bullet listing or numbers when specified in the template.",
          },
          {
            role: "user",
            content:
              "Analyze the following data and generate a detailed HTML report. Ensure the report includes specific insights based on the data, formatted as HTML with the following structure:",
          },
          {
            role: "user",
            content,
          },
          { role: "user", content: userMessage },
        ],
        max_tokens: maxTokens,
      }
    );

    return result.data;
  } catch (error) {
    console.error("Error fetching data:", error);

    return null;
  }
};
