import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ReactComponent as LightBulbBlack } from "assets/lightbulbblack.svg";
import { useStyles } from "./../styles";

interface ReportProps {
  htmlContent: string;
}

const ReportComponent: React.FC<ReportProps> = ({ htmlContent }) => {
  const classes = useStyles({});

  return (
    <Grid container className={classes.insightsContainerData}>
      {/* Use dangerouslySetInnerHTML to render the HTML */}
      {htmlContent ? (
        <>
          <p className={classes.pageTitleH2} style={{ width: "100%" }}>
            <LightBulbBlack />
            Insight
          </p>
          <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </>
      ) : (
        <Grid className={classes.loaderWrapper} container>
          <p className={classes.pageTitleH2} style={{ width: "100%" }}>
            <LightBulbBlack />
            Insight
          </p>
          <div
            className={`${classes.skeletonLoader} ${classes.skeletonText}`}
          ></div>
          <div
            className={`${classes.skeletonLoader} ${classes.skeletonNumber}`}
          ></div>
        </Grid>
      )}
    </Grid>
  );
};

export default ReportComponent;
