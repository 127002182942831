export const contentMap = {
  engagement: `
  <div>
    <h3>Overall Engagement Trend</h3>
    <ul>
      <!-- Insights on engagement trends go here -->
      <!-- <li><strong>* </strong> Engagement Trend 1</li> -->
      <!-- <li><strong>* </strong> Engagement Trend 2</li> -->
      <!-- <li><strong>* </strong> Engagement Trend 3</li> -->
    </ul>
    <h3>Engagement Distribution</h3>
    <ul>
      <!-- Insights on engagement distribution go here -->
      <!-- <li><strong>* </strong> Engagement Distribution 1</li> -->
      <!-- <li><strong>* </strong> Engagement Distribution 2</li> -->
    </ul>
    <h3>Engagement Performance Analysis</h3>
    <ul>
      <!-- Performance analysis insights go here -->
      <!-- <li><strong>* </strong> Performance Analysis 1</li> -->
    </ul>
  </div>
  `,
  reach: `
  <div>
    <h3>Reach Performance</h3>
    <ul>
      <!-- Insights on reach analysis go here -->
      <!-- <li><strong>* </strong> Reach Analysis 1</li> -->
      <!-- <li><strong>* </strong> Reach Analysis 2</li> -->
    </ul>

    <h3>Trend Analysis</h3>
    <ul>
      <!-- Insights on trend analysis go here -->
      <!-- <li><strong>* </strong> Trend Analysis 1</li> -->
      <!-- <li><strong>* </strong> Trend Analysis 2</li> -->
    </ul>
  </div>
  `,
  engagementRate: `
  <div>
  <p>
  <!-- Insights on engagement rate go here -->
  </p>
  <h3>Recommendations</h3>
    <ul>
      <!-- recommendations go here number list goes here. limit number of recommendations to 4-->
      <!-- <li><strong>1. </strong> Recommendation 1</li> -->
      <!-- <li><strong>2. </strong> Recommendation 2</li> -->
      <!-- <li><strong>3. </strong> Recommendation 3</li> -->
      <!-- <li><strong>4. </strong> Recommendation 4</li> -->
    </ul>
  </div>
  `,

  content: `
<div>
  <p class="ai-insight-paragraph">
  <!-- Insights on content performance go here . We dont need headers-->
  </p>
</div>
  `,
};
