// ColumnBarChart.tsx
import React from "react";
import ApexCharts from "react-apexcharts";
import { ColumnBarChartProps } from "../types";
import "./styles.css"; // Make sure to import the CSS file

const ColumnBarChart: React.FC<ColumnBarChartProps> = ({
  series,
  categories,
  title,
}) => {
  const options = {
    chart: {
      type: "bar",
      height: "100%", // Ensure the height is responsive
      width: "100%", // Ensure the width is responsive
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: categories.map((dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        }).format(date);
      }),
      type: "date",
    },
    yaxis: {
      title: {
        text: "Values",
      },
    },
    fill: {
      opacity: 1,
    },

    tooltip: {
      y: {
        formatter: function (val: number) {
          return `${val}`;
        },
      },
    },
  };

  return (
    <div className="chart-container">
      <h2>{title}</h2>
      <ApexCharts options={options} series={series} type="bar" height={350} />
    </div>
  );
};

export default ColumnBarChart;
