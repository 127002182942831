import React from "react";
import { useStyles } from "../styles";
import { Grid } from "@material-ui/core";

export enum MetricType {
  NUMBER = "number",
  STRING = "string",
}
interface IProps {
  loading: boolean;
  setLoading: (e) => void;
  cardTitle: string;
  cardNumber: any;
  type?: MetricType;
}
const MetricCard = ({
  loading,
  setLoading,
  cardTitle,
  cardNumber,
  type = MetricType.NUMBER,
}: IProps) => {
  const formatNumber = (number) => {
    if (type === MetricType.NUMBER) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return number;
    }
  };

  const classes = useStyles({});

  return (
    <Grid container>
      <Grid item xs={12} md={12} className={classes.metricCardWrapper}>
        {loading ? (
          // Skeleton Loader
          <div className={classes.metricCard}>
            <div className={classes.loaderWrapper}>
              <div
                className={`${classes.skeletonLoader} ${classes.skeletonText}`}
              ></div>
              <div
                className={`${classes.skeletonLoader} ${classes.skeletonNumber}`}
              ></div>
            </div>
          </div>
        ) : (
          // Actual Content
          <div className={classes.metricCard}>
            <p className={classes.metricCardtext}>{cardTitle}</p>
            <p className={classes.metricCardNumbers}>
              {formatNumber(cardNumber)}
            </p>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default MetricCard;
